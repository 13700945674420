import React from 'react';
import './footer.css';
const Footer = () => {
  return (
    <footer className='footer' id='footer'>
        <div className='footer-info'>
        <h1 className='footer-h1'>Professor Dinabandhu Sahoo Ph.D</h1>

            <div className='footer-div'>Email: dbsahoo@hotmail.com</div>
            <div className='footer-div'>Professional Email: dbsahoobotany@gmail.com</div>
            <div className='footer-div'>Address: Department of Botany,University of Delhi,Delhi-110007</div>
            <div className='footer-div'>Phone: +91-8132953575 / +91-9818165197</div>
        </div>
        <div className='footer-menu'>
            <a href = "/#home">Home</a>
            <a href = "/#biogradivhy">Biography</a>
            <a href = "/#research">Research</a>
            <a href = "/#publications">Publications</a>
            <a href = "/#footer">Contact</a>


        </div>
      
    </footer>
  )
}

export default Footer;
