import React from 'react'
import "./aa.css"
const aa = () => {
  return (
    <div className='aa_container'>

      <div className="aa_header_container">
        <p>Achievements & Awards</p>
      </div>

      <div className="aa_content_container">

        <div className="aa_text_container">
          <div className="aa_text_header">
            <h4>Notable Honors</h4>
          </div>

          <div className="aa_text_content">
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>

            </ul>
          </div>

        </div>
        <div className="aa_text_container">
          <div className="aa_text_header">
            <h4>Awards</h4>
          </div>
          <div className="aa_text_content">
            <ul>
              <li>Elected Fellow, International Society for Energy, Environment and Sustainability,2020</li>
              <li>Elected for Rashtra Ratna Jeevan Gaurav award in January 2020</li>
              <li> Elected Fellow, National Academy of Agriculture Sciences (NAAS), 2019</li>
              <li>Scientific Advisor to the Hon’ble Chief Minister,Government of Meghalaya wef March 2019.</li>
              <li>Doctor of Science (D.Sc.) Honoris Causa by Kalinga Institute of Industrial Technology
                (KIIT) University, India 2018.</li>
              <li>Elected Fellow, The National Academy of Sciences (NASI), India 2017.</li>
              <li> Elected Honorary Fellow of Biotech Research Society of India (BRSI), 2017</li>
              <li>Advisor, Higher Education and Research, Government of Manipur, Feb 2018 – 2021.</li>
              <li>Advisor, Climate Change to Government of Manipur, Feb 2018 – 2021.</li>
              <li>  Professor Harihar Pattnaik Memorial Award in Algology 2017 for outstanding contribution
                in the field of Algae by Orissa Botanical Society</li>
              <li> YSRK Sarma Memorial Award 2015 for outstanding contribution in the field of Phycology
                and Marine Science.</li>
              <li>India Sustainability Leadership Award 2015 for Sustainable Development. </li>
              <li>Fellowship of the Year Award 2009 from the National Environmental Science Academy for outstanding contributions to Marine Environment. The award was presented by the Union Minister of Environment and Forests in New Delhi, India, on October 4, 2009. </li>
              <li>Awarded M. P. Purohit Memorial Award for popularization of plant science in the state of
                Orissa for the year 2009.</li>
              <li>Awarded International Training Fellowship by Swedish International Development
                Agency (SIDA) for Marine Management and Good Governance 2009.</li>
              <li>Awarded Biotechnology Overseas Associateship, Department of Biotechnology,
                Government of India, New Delhi to visit USA during 2002.</li>
              <li> Awarded INSA-JSPS International Exchange Fellowship, during 2000 to visitJapan.</li>
              <li>Young Scientist award by Aus-Aid at 4th Asia-Pacific conference on Agricultural Biotechnology, Darwin, Australia, 1998. </li>
              <li>Young Scientist Award, 1997. </li>
              <li>Zahoor Qasim Gold medal, 1997.</li>
              <li> Recipient of Visiting Fellowship, Smithsonian Institution, Washington D.C., USA, 1992.</li>
              <li>  Recipient of Research Associateship by Council of Scientific and Industrial Research (1989-
                95).</li>
              <li>  Recipient of Senior Research Fellowship by Council of Scientific and Industrial Research
                (1987-88).</li>
              <li>Recipient of National Scholarship (1981-83).</li>



            </ul>
          </div>
        </div>
      </div>

    </div>

  )
}

export default aa
