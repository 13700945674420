import React from 'react'
import "./About.css"
import DDS from "../../photos/hero.jpg"

const About = () => {
    return (
        <div className='about_container'>
            <div className="about_content_container">
                <div className="about_text_container">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugit commodi ut magnam quod, tempore vel vitae officia sequi voluptate veniam facere dolores hic est architecto aperiam? Nesciunt eos nemo impedit ullam ipsa, at temporibus aliquam ipsum dolorem ex unde quia voluptatibus deleniti beatae iure quos odit odio quidem maiores dolores, qui tempora similique! Cumque nulla soluta velit omnis accusantium aliquam.
                </div>
                <div className='about_contact_link_container'>
                    <button>
                     <a href="/contact">contact</a>
                    
                        </button>
                </div>
            </div>
            <div className="about_image_container">
                <img src={DDS} className='about_img' />
            </div>

        </div>
    )
}

export default About