import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Biography from './pages/Biography/Biography';
import Contact from './pages/Contact/Contact';
import Publications from './pages/Publications/Publications';
import Research from './pages/Research/Research';
import Paper from "./pages/papers/Papers"
import './App.css';
import Header from "./components/header/header";
// import Hero from './components/hero/hero';
function App() {
  return (
    <div >
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/biography" element={<Biography />} />
        <Route path="/publications" element={<Publications />} />
        <Route path="/research" element={<Research />} />
        <Route path='/paper' element={<Paper />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {/* <Hero /> */}
    </div>
  );
}

export default App;







{/* <BrowserRouter>
<Routes>
  <Route index element={<Home />} />
  <Route path="/home" element={<Home />} />
  <Route path="/biography" element={<Biography />} />
  <Route path="/publications" element={<Publications />} />
  <Route path="/research" element={<Research />} />
  <Route path="/contact" element={<Contact />} />

</Routes>
</BrowserRouter> */}