import React from 'react';
import { papersData } from './Papersdata';  
import './Papers.css';

const publication = () => {
  return (
    <div className="publication-container">
      <h2 className="publication-header">Scientific Papers & Intellectual Contributions</h2>
      <div className="publication-grid">
        {papersData.map((paper) => (
          <div key={paper.id} className="publication-card">
            <img src={paper.imageUrl} alt={paper.title} className="paper-image" />
            <p className="publication-title">"{paper.title}" - {paper.journal}, {paper.year}.</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default publication;
