export const booksData = [
    {
      id: 1,
      title: "Production and Characterization of Bioactive Peptides from Soybean",
      description: "Food Chemistry, 2022",
      image: "immage1"
    },
    {
      id: 2,
      title: "Another Scientific Paper Title",
      description: "Another Journal, 2021",
      image: "image2",
    },
    
  ];
  