import Header from '../../components/header/header'

import React from 'react'

const Research = () => {
    return (
        <div>Research</div>
    )
}

export default Research