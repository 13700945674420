export const papersData = [
    {
      id: 1,
      imageUrl: 'path_to_image_1', // Replace with your actual image path
      title: "Production and Characterization of Bioactive Peptides from Soybean",
      journal: "Food Chemistry",
      year: 2022,
    },
    {
      id: 2,
      imageUrl: 'path_to_image_2',
      title: "Production and Characterization of Bioactive Peptides from Soybean",
      journal: "Food Chemistry",
      year: 2022,
    },
    {
      id: 3,
      imageUrl: 'path_to_image_3',
      title: "Production and Characterization of Bioactive Peptides from Soybean",
      journal: "Food Chemistry",
      year: 2022,
    },
    {
      id: 4,
      imageUrl: 'path_to_image_4',
      title: "Production and Characterization of Bioactive Peptides from Soybean",
      journal: "Food Chemistry",
      year: 2022,
    }
  ];
  