import React from 'react';
import { booksData } from './Publication_data';  
import './Publications.css';

const Books = () => {
  return (
    <div className='books-container' id="publications">
      {booksData.map((book) => (
        <div key={book.id} className='books-text-container'>
          <h2 className='books-h2'>{book.title}</h2>
          <p className='books-p'>{book.description}</p>
          <button className='books-btn'>Order</button>
          <div className='book-img-container'>
            <img src={book.image} alt={book.title} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Books;