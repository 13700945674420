import React from 'react'
import "./header.css"
import { Link, NavLink } from 'react-router-dom'
const header = () => {
    return (
        <header>
            <Link to="/" className='title'> Prof. Dinabandhu Sahoo </Link>
            <ul>
                <li> <NavLink to="/home" >  Home </NavLink> </li>
                <li> <NavLink to="/biography" >  Biography </NavLink> </li>
                <li> <NavLink to="/publications"> Publications  </NavLink> </li>
                <li> <NavLink to="/research">  Research </NavLink> </li>
                <li> <NavLink to="/paper">Paper</NavLink></li>
                <li> <NavLink to="/contact" > Contact  </NavLink> </li>

            </ul>
        </header>
    )
}

export default header
