import React from 'react'
import "./hero.css"

import image from "../../photos/hero.jpg"
const hero = () => {
    return (
        <div className='hero_container'>
            <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cum recusandae consequatur hic! Impedit veniam culpa nobis voluptatem eligendi, minus tempora.</h3>
        </div>
    )
}

export default hero