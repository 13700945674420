
import Header from '../../components/header/header'
import React from 'react'
import Hero from '../../components/hero/hero';
import Aboutus from '../../components/About/About';
import AA from "../../components/AA/aa"
import Footer from "../../components/footer/Footer"


const Home = () => {
    return (
        <>
            <Hero />
            <Aboutus />
            <AA />
            <Footer />
        </>
    )
}

export default Home